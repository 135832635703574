import React from "react"
import PropTypes from "prop-types"

// Utilities
import * as styles from '../styles/tagspage.module.scss'

// Components
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import CreateList from "../components/createlist"
import { createTagsNameMap } from "../components/functions/createconst"
import TagsList from "../components/tagslist"


const OthersPage = ({
  data,
  location,
}) => {
  const posts = data.posts.nodes
  const title = data.site.siteMetadata.title
  const group = data.allMdx.group
  const taggroup = data.taggroup.group

  const tagsnamemap = createTagsNameMap()



  return(
    <Layout location={location} title={title}>
      <div className = {styles.bodywrapper}>
          <Helmet title={title} />
          <div>
            <h1>その他のページ一覧</h1>
          </div>
          <CreateList posts = { posts }></CreateList>
          <div>キーワードから探す</div>
          <TagsList istop={true}></TagsList>

      </div>
    </Layout>
    
  )

}


OthersPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default OthersPage

export const pageQuery = graphql`
  query {
    taggroup: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    posts: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category1 : { in: "Others" } } }
      ) 
      {
          nodes {
              id
              excerpt
              fields {
                  slug
              }
              frontmatter {
                  category2
                  category1
                  date(formatString: "MMMM DD, YYYY")
                  description
                  title
                  tags
                  hero{
                      childImageSharp{
                          gatsbyImageData(
                              width: 600
                              placeholder: NONE
                          )
                      }
                  }
              }
          }
          totalCount
      }
    
    allMdx(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    site {
      siteMetadata {
        title
      }
    }
    
  }
`